// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  BASE_URL: 'https://api.ebiz.basf.com',
  AZURE_AUTH_URL: 'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55/oauth2/v2.0/authorize',
  OAUTH_CLIENT_ID: 'fac72f3e-92b9-4a3a-8fd1-172d79baf795',
  SCOPE: 'openid profile offline_access',
  EBIZ_ROLE: 'APN-EBIZ-EBILL_EBIZ_PROD_USER',
  EBILL_ROLE: 'APN-EBIZ-EBILL_EBILL_PROD_USER',
  OAUTH_REDIRECT_URI: 'https://ebiz.basf.com/index.html',
  ENV_URL: 'https://ebiz.basf.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *import 'zone.js/dist/zone-error';  // Included with Angular CLI.
*/